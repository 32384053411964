<template>
  <section class="Home">
    <div class="Home-Header">
      <div class="Container">
        <h1 class="Title--h1 Home-HeaderTitle">
          Market research and measurable marketing Solutions
        </h1>
      </div>
    </div>
    <div class="Home-Services">
      <div class="Container">
        <div class="Home-ServicesList">
          <div class="Home-ServicesItem"
               v-for="(item, index) in listInfo"
               :key="index"
          >
            <router-link to="/services" class="Home-ServicesTitle">{{ item.title }}</router-link>
            <div class="Home-ServicesImgBox">
              <img :src="require(`@/assets/images/${item.icon}`)" alt="" class="Home-ServicesImg">
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      listInfo: [
        {title: "Lead Nurture Marketing", icon: "megafon-colored.svg"},
        {title: "Website Optimization", icon: "rocket-colored.svg"},
        {title: "Creative Services", icon: "diamond-colored.svg"},
        {title: "DIGITAL COMPETITIVE ANALYSIS", icon: "bar-colored.svg"},
        {title: "EMAIL MARKETING", icon: "mail-colored.svg"},
        {title: "Paid Advertising", icon: "darts-colored.svg"},
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.Home {
  padding: 108px 0;

  &-Header {
    padding: 90px 0;
    background-image: url(~@/assets/images/home-bg-1920.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    @media (min-width: $screen-l) {
      padding: 134px 0 198px;
    }
  }

  &-HeaderTitle {
    width: 280px;
    margin: 0 auto;

    @media (min-width: $screen-l) {
      width: 650px;
    }

    @media (min-width: $screen-xl) {
      width: 950px;
    }
  }

  &-ServicesList {
    margin-top: -45px;
    display: grid;
    grid-template-columns: repeat(1, 300px);
    grid-gap: 30px 31px;
    justify-content: center;
    text-align: center;

    @media (min-width: $screen-m) {
      grid-template-columns: repeat(2, 350px);
    }

    @media (min-width: $screen-xl) {
      grid-template-columns: repeat(3, 350px);
      margin-top: -85px;
    }
  }

  &-ServicesItem {
    padding: 22px 0 0;
    border-radius: 4px;
    background-color: var(--color-text-main3);
    box-shadow: 0px -2px 10px rgba(29, 29, 37, 0.15);
  }

  &-ServicesTitle {
    margin-bottom: 13px;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.23;
    letter-spacing: 0.02em;
    text-transform: uppercase;

    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      left: 0;
      bottom: -2px;
      background-color: var(--color-text-main);
      opacity: 0;
      transition: .3s;
    }

    &:hover {
      &:after {
        opacity: 1;
      }
    }
  }

  &-ServicesImgBox {
    height: 95px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    background-color: var(--color-text-main2);
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      border: 16px solid transparent;
      border-left: 175px solid var(--color-text-main3);
      border-top: 0 solid var(--color-text-main3);
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      border: 16px solid transparent;
      border-right: 175px solid var(--color-text-main3);
      border-top: 0 solid var(--color-text-main3);
    }
  }

  &-ServicesImg {
    display: block;
    margin: 0 auto;
  }
}
</style>